import React, { Component } from "react"
import { navigate } from "gatsby"
import Seo from '../components/seo';

const isBrowser = typeof window !== "undefined";

class Portret extends Component {
  render() {
    if (isBrowser) {
      navigate("/portretten/")
    }
    let title = this.props.pageContext.data.title;
    return (
      <>
        <Seo
          title={title}
          article={false}
        />
      </>
    );
  }
}

export default Portret
